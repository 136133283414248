import React from "react"
import Layout from "../components/web/layout"
import SEO from "../components/web/seo"

const IndexPage = (props) => {
  return (
    <Layout>
      <SEO title="Email Signup" />
      <h1>Thank you!</h1>
      <p>
        We've just sent you a welcome email.
        If you don't see it, it might be in your promotions tab.
        You can add the email to your primary tab to make sure you don't miss anything.
      </p>
    </Layout>
  )
}

export default IndexPage
